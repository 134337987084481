.login {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

h3{
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 500;
}
  
.lContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
  
.lInput{
    height: 30px;
    width: 300px;
    padding: 10px 20px;
    font-size: 16px;
    margin-bottom: 10px;
}
  
.lButton {
    border: none;
    padding: 10px 20px;
    background-color: #0071c2;
    font-size: 16px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    width: 100px;
    border-radius: 5px;
    margin-top: 20px;
}
  
.lButton:disabled{
    background-color: #0071c28c;
    cursor: not-allowed;
}