.sidenav{
    flex: 1;
    border-right: 2px solid rgb(230, 227, 227);
    min-height: 100vh;
    background-color: white;
    hr{
        height: 0px;
        border: 0.5px solid rgb(230, 227, 227);
    }
}

.top{
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    img{
        width: 160px;
    }
}

.center{
    padding-left: 20px;
    overflow: scroll;
    height: calc(100vh - 130px);
    ul{
        list-style: none;
        a{
            text-decoration: none;
        }

        .title{
            font-size: 13px;
            font-weight: 500;
            color: rgba(128, 128, 128, 0.655);
            margin-top: 20px;
        }

        li{
            display: flex;
            align-items: center;
            padding: 10px;
            cursor: pointer;

            &:hover{
                background-color: whitesmoke;
            }

            .icon{
                font-size: 20px;
                margin-right: 8px;
                color: #dc4535;
            }

            span{
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0.5px;
                color: #888;
            }
        }
        .active{
            background-color: whitesmoke;
        }
    }
}

.bottom{
    display: flex;
    align-items: center;
    margin: 10px;

    .colorOptions{
        width: 25px;
        height: 25px;
        border-radius: 5px;
        border: 1px solid #888;
        cursor: pointer;
        margin: 10px;

        &:nth-child(1){
            background-color: whitesmoke;
        }
        &:nth-child(2){
            background-color: #333;
        }
    }
}