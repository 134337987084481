.App{
    display: flex;

    .container{
        flex: 5;
    }
}

.dark{
    background-color: #111;
    color: whitesmoke;
    
    .grid{
        stroke: gray;
    }

    .homeTable{
        background-color: #222;
        
        .tableCell{
            color: lightgray;
        }
    }

    .navbar{
        border-color: gray;
        .search{
            border-color: gray;
            input{
                color: whitesmoke;
            }
        }
    }

    .sidenav{
        background-color: #111;
        border-color: gray;

        hr{
            border-color: gray;
        }

        li{
            &:hover{
                background-color: #333;
            }
        }
    }
    .data-table{
        .dataGrid{
            color: rgb(140, 140, 140);
            border-color: gray;

            .viewBtn{
                color: lightgray;
                border-color: lightgray;
            }
            .deleteBtn{
                color: rgb(252, 53, 53);
                border-color: rgb(252, 53, 53);
            }
            .cellWithStatus{
                color: lightgray;
            }
        }
    }

    .userShowInfoTitle{
        color: lightgray;
    }

    .single{
        .top{
            p{
                color: gray;
            }
        }
        .userShowIcon{
            color: gray;
        }
    }

    input{
        background-color: transparent;
        color: lightgray;
    }
    select{
        background-color: transparent;
        color: white;
        outline: none;
    }
}