.navbar{
    height: 60px;
    border-bottom: 2px solid rgb(230, 227, 227);
    display: flex;
    align-items: center;
    z-index: 2;
}

.wrapper{
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search{
        display: flex;
        align-items: center;
        border: 1px solid lightgray;
        padding: 3px 10px;

        .icon{
            cursor: pointer;
        }

        input{
            border: none;
            outline: none;
            background-color: transparent;
        }
    }

    .items{
        display: flex;
        align-items: center;

        .item{
            display: flex;
            align-items: center;
            margin: 0 20px;
            position: relative;
            cursor: pointer;
            .counter{
                width: 16px;
                height: 16px;
                background-color: #dc3545;
                border-radius: 50%;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                font-weight: 500;
                position: absolute;
                top: -4px;
                right: -4px;
            }
        }
    }
}