.carousel-wrapper{
    height: calc(100dvh - 74px);
    overflow-y: scroll;

    .create-box{
        margin: 25px;
        padding: 20px;
        background-color: whitesmoke;
        color: #000 !important;
        width: 75vw;
        border-radius: 8px;
        display: flex;

        .createForm{
            width: 60%;

            h3{
                margin-bottom: 10px !important;
            }

            .formItem{
                margin: 10px 0px;

                span{
                    font-size: 12px;
                }

                label{
                    margin-right: 8px;
                }

                .formInput{
                    border: none;
                    outline: none;
                    border-bottom: 1px solid black;
                    width: 400px;
                    background-color: whitesmoke;
                    color: #000 !important;
                }
            }

            .formCreateButton{
                padding: 8px 24px;
                background-color: cornflowerblue;
                color: white;
                font-weight: 500;
                font-size: 15px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
                transition: all 200ms ease-in;
            }

            .formCreateButton:hover{
                background-color: rgba(53, 53, 255, 0.85);
            }
        }

        .instructions{
            width: 40%;
            p{
                padding: 3px 0px;
                font-size: 14px;
            }
        }
    }
}