// .homeContainer{
//         flex: 5;

//         .widgets{
//             display: flex;
//             padding: 20px;
//             gap: 20px;
//         }

//         .charts{
//             height: calc(100vh - (340px));
//             width: calc(100% - (40px));
//             padding: 20px;
//         }

//         .table{
//             margin: 25px;
//             margin-top: 100px;
//             width: calc(100% - (50px));
//         }
// }

.homeContainer{
    width: 100%;
    height: 100%;
    img{
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
    }
}

.bottom-heading{
    position: absolute;
    top: 38%;
    text-align: center;
    font-size: 3.5rem;
    letter-spacing: 2.5px;
    color: #73B6C2;
    margin-bottom: 120px;
}

.homeWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 70px);
    width: calc(100vw - 240px);
}