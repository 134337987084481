.single {
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: calc(100vh - 100px);
    overflow: scroll;
    padding-bottom: 20px;
 }
   
   .form-top {
     display: flex;
     justify-content: space-between;
     margin: 10px 25px;
     align-items: center;
   
     p {
       font-size: 24px;
       font-weight: 500;
     }
   }
   
   .client {
     display: flex;
     justify-content: center;
   }
   
   .noteUpdate {
     width: 600px;
     background-color: #f9f9f9;
     padding: 20px;
     border-radius: 4px;
     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
     margin-left: 0px !important;
   
     .noteUpdateTitle {
       font-size: 24px;
       font-weight: 600;
       margin-bottom: 20px;
       text-align: center;
     }
   
     .noteUpdateForm {
         display: flex;
         flex-direction: column;
         gap: 24px;
   
       .noteUpdateItem {
         display: flex;
         flex-direction: column;
   
         label {
           margin-bottom: 10px;
           font-size: 14px;
           font-weight: 600;
           color: gray;
         }
   
         .noteUpdateInput,
         select {
           width: 100%;
           padding: 12px;
           border: 1px solid lightgray;
           border-radius: 5px;
           font-size: 14px;
         }
         .noteUpdateInput{
             width: calc(100% - 28px) !important;
         }
       }
   
       .noteUpdateButton {
         width: 100%;
         padding: 12px;
         background-color: cornflowerblue;
         color: white;
         border: none;
         border-radius: 5px;
         cursor: pointer;
         font-weight: 600;
         transition: all 0.3s ease;
   
         &:hover {
           background-color: rgba(53, 53, 255, 0.85);
         }
       }
     }
   }
   
   .Btn {
     padding: 8px 24px;
     background-color: cornflowerblue;
     color: white;
     font-weight: 500;
     border: none;
     border-radius: 5px;
     cursor: pointer;
     transition: all 0.2s ease-in;
   
     &:hover {
       background-color: rgba(53, 53, 255, 0.85);
     }
   }
   
   .updated {
     color: green;
     font-weight: 500;
   }
   